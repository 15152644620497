<template>
  <div class="container">
    <div class="row ">
    <img src="./iheader.png" alt="header" width="100%" />
  </div>
    <h2 class="mt-2 text-center border p-2"> <b> INVOICE / فاتورة ضريبية </b></h2>
    <div class="w-100">
      <div class="float-left">
        <vue-qrcode :value="invoice.qrcode" :options="{ width: 150 }"></vue-qrcode>
      </div>
      <table class="line-height table-bordered  float-right">
        <tr>
          <td>
            <h3> Invoice No / الفاتورة رقم : </h3>
          </td>
          <td class="text-center">
            <h3>{{ invoice.invoice_id }}</h3>
          </td>
        </tr>
        <tr>
          <td>
            <h3> REF No / : رقم المرجع </h3>
          </td>
          <td class="text-center">
            <h3>{{ invoice.ref_invoice }}</h3>
          </td>
        </tr>
        <tr>
          <td><span>
              <h3> Date / التاريخ :</h3>
            </span></td>
          <td class="text-center">
            <h3> {{ invoice.created_at | moment("MM/DD/YYYY") }} </h3>
          </td>
        </tr>
        <tr>
          <td>
            <h3> Payment Due Date /تاريخ الإستحقاق :</h3>
          </td>
          <td class="text-center">
            <h3> {{ invoice.payment_due | moment("MM/DD/YYYY") }} </h3>
          </td>
        </tr>
        <tr>
          <td>
            <h3> Vat No / الرقم الضريبي :</h3>
          </td>
          <td class="text-center">
            <h3> 300047805100003 </h3>
          </td>
        </tr>
        <tr>
          <td>
            <h3> Job No / رقم المشروع :</h3>
          </td>
          <td class="text-center">
            <h3>{{ invoice.po }} </h3>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <table class="table w-100 border">
        <tr>
          <td>
            <h4>Customer Name:</h4>
          </td>
          <td>
            <h4 class="float-right">اسم العميل</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>YANBU ARAMCO SINOPEC REFINING COMPANY Ltd. (YASREF)</h4>
          </td>
          <td>
            <h4 class="float-right">شركة ينبع أرامكو سينوبك للتكرير ( ياسرف) المحدودة </h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>Maintenance Dept.</h4>
          </td>
          <td>
            <h4 class="float-right">قسم الصيانة</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>Contracts Unit, Maintenance Building</h4>
          </td>
          <td>
            <h4 class="float-right"> وحدة العقود . مبني الصيانة</h4>
          </td>
        </tr>
        <tr>
        <tr>
          <td>
            <h4>3707 Industrial Area, Unit No. 1 </h4>
          </td>
          <td>
            <h4 class="float-right"> الوحدة رقم 1 - 3707 المنطقة الصناعية</h4>
          </td>
        </tr>
        <tr>

          <td>
            <h4>Yanbu 46476 - 8877, K.S.A.</h4>
          </td>
          <td>
            <h4 class="float-right"> ينبع 46476-8877. المملكة العربية السعودية</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h4>VAT No. 300466844300003</h4>
          </td>
          <td>
            <h4 class="float-right"> 300466844300003 : ألرقم الضري </h4>
          </td>
        </tr>
      </table>
    </div>
    <table class="table table-borderless">
      <tbody>
        <tr>
          <td class="text-right">
            <h4>Amount Billed:</h4>
          </td>
          <td class="text-center border">
            <h3>SAR {{ invoice_net_amount }}</h3>
          </td>
          <td class="text-left">
            <h4>:مبلغ الفاتورة</h4>
          </td>
        </tr>
      </tbody>
    </table>
    <div>
      <table class="border table line-height table-bordered">
        <th class="text-center"><span>Item/البند </span></th>
        <th class="text-center"><span>Description/مواصفات البضاعة</span></th>
        <th class="text-center"><span>Amount (SAR) (المبلغ ( ريال سعودي</span></th>
        <tr>
          <td class="text-center">
            <h5>1 </h5>
          </td>
          <td colspan="2">
            <div class="row">
              <span class="col-md-6">{{ items.item }}</span>
              <span class="col-md-6 text-right">{{ items.item_arabic }} </span>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td colspan="2">
            <div class="row">
              <span class="col-md-2">Covered Period: </span>
              <span class="col-md-3"> {{ covered_period_response.item }} </span>
              <span class="col-md-3 text-center"> {{ covered_period_response.item_arabic }}</span>
              <span class="col-md-4  text-right"> ا لفترة المشمولة</span>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td colspan="2">
            <div class="row">
              <span class="col-md-2">Contract No. </span>
              <span class="col-md-5">{{ invoice.contract_no }}</span>
              <span class="col-md-5 text-right">رقم العقد</span>
            </div>
          </td>
        </tr>

        <tr>
          <td></td>
          <td colspan="2">
            <div class="row">
              <span class="col-md-2">Contract Period </span>
              <span class="col-md-5"> From {{ invoice.contract_start | moment("MM/DD/YYYY") }} to {{
                invoice.contract_end |
                  moment("MM/DD/YYYY")
              }}</span>
              <span class="col-md-5 text-right">مدة العقد</span>
            </div>
          </td>
        </tr>

        <tr>
          <td></td>
          <td colspan="2">
            <div class="row">
              <span class="col-md-2">PO No. </span>
              <span class="col-md-5">{{ invoice.po }}</span>
              <span class="col-md-5 text-right">رقم الإتفاقية </span>
            </div>
          </td>
        </tr>

        <tr>
          <td></td>
          <td colspan="2">
            <div class="row">
              <span class="col-md-2">Title Name : </span>
              <span class="col-md-5">{{ items.item }} </span>
              <span class="col-md-5 text-right">{{ items.item_arabic }}</span>
            </div>
          </td>
        </tr>

        <tr>
          <td></td>
          <td colspan="2"><span>(Details are included as attachment)</span></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="row">
              <div class="col-md-6"><span>PO AMOUNT</span></div>
              <div class="col-md-6 text-right">
                <span>القيمة </span>
              </div>
            </div>
          </td>
          <td class="text-right">
            <span> {{ invoice_total_amount }}</span>
          </td>
        </tr>


        <tr>
          <td></td>
          <td>
            <div class="row">
              <div class="col-md-6"><span>VAT (15%)</span></div>
              <div class="col-md-6 text-right">
                <span>(%15) ضريبة القيمة المضافة</span>
              </div>
            </div>
          </td>
          <td class="text-right">
            <span> {{ invoice_vat_amount }}</span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="row">
              <div class="col-md-6"><span>GROSS AMOUNT</span></div>
              <div class="col-md-6 text-right"><span>القيمة بعد ضريبة</span></div>
            </div>
          </td>
          <td class="text-right">
            <span class="border-top">{{ invoice_gross_amount }}</span>
          </td>
        </tr>

        <tr>
          <td></td>
          <td>
            <div class="row">
              <div class="col-md-6"><span>RETENTION (10%)</span></div>
              <div class="col-md-6 text-right">
                <span>(%10) المبلغ المستر جمع </span>
              </div>
            </div>
          </td>
          <td class="text-right">
            <span>- {{ invoice_rentention_amount }}</span>
          </td>
        </tr>


        <tr>
          <td></td>
          <td>
            <div class="row">
              <div class="col-md-6"><span>NET AMOUNT</span></div>
              <div class="col-md-6 text-right">
                <span> اجمالي المستحق</span>
              </div>
            </div>
          </td>
          <td class="text-right">
            <span class="border-top border-bottom"> {{ invoice_net_amount }}</span>
          </td>
        </tr>

        <tr>
          <td colspan="2" class="text-center">
            <span>{{ invoice.amount_words }}</span>
          </td>
          <td class="text-right">
            <span>SAR {{ invoice_net_amount }}</span>
          </td>
        </tr>

        <tr>
          <td></td>
          <td class="text-center">
            <span>
              {{ invoice.amount_words_ar }}</span>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
    <div>
      <table class="border table table-bordered line-height">
        <tr>
          <td></td>
          <td class="text-center">
            <span> Bank A/C Details تفاصيل الحساب البنكي</span>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span>Beneficiary Name:</span></td>
          <td class="text-center">
            <span>ABDULRHMAN BUSAILI SONS CO. FOR ELEC. AND MECH.WORK</span>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td><span> أسم المستفيد</span></td>
          <td class="text-center">
            <span>
              شركة ابناء عــبـدالرحــمــن علي الـبـصيـلي للاعمال الكهربائـيـة والميــكانيــكيـــة
            </span>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td><span> Beneficiary Bank Name:</span></td>
          <td class="text-center"><span>Riyadh Bank</span></td>
          <td rowspan="3" class="text-center">
            <h3>Department Section</h3>
            <br />
            <span>القسم</span>
          </td>
          <td rowspan="3" class="text-center">
            <h3>Accounting Section</h3>
            <br />
            <span>قسم المحاسبة</span>
          </td>
        </tr>
        <tr>
          <td><span>اسم البنك المستفيد</span></td>
          <td class="text-center"><span>بنك الرياض</span></td>
        </tr>
        <tr>
          <td><span>Branch Name:</span></td>
          <td class="text-center"><span>AHSSA St. MALAZ, Riyadh. 297</span></td>
        </tr>
        <tr>
          <td><span>اسم الفرع</span></td>
          <td class="text-center"><span>شارع الأحساء , الملــــز , الرياض 297</span></td>
          <td rowspan="4"></td>
          <td rowspan="4"></td>
        </tr>
        <tr>
          <td><span>Beneficiary A/C No.: رقم الحساب المستفيد</span></td>
          <td class="text-center"><span>2971292599950</span></td>
        </tr>
        <tr>
          <td><span>IBAN No.: الايبان</span></td>
          <td class="text-center"> <span>SA4220000002971292599950</span></td>
        </tr>
        <tr>
          <td><span>Swift Code: السويفت</span></td>
          <td class="text-center"><span>RIBL SARI</span></td>
        </tr>
      </table>
    </div>
    <div class="m-0 p-0">
      <table class="border line-height table table-bordered">
        <tr>
          <td colspan="3" class="text-center">
            <span> YASREF APPROVAL موافقة ياسرف </span>
          </td>
        </tr>
        <tr>
          <td class="text-center"><span>NAME الاسم </span></td>
          <td class="text-center"><span>SIGNATURE التوقيع</span></td>
          <td class="text-center"><span>DATE التاريخ</span></td>
        </tr>
        <tr>
          <td>
            <h4>Verified By :</h4>
            <h4>:التحقق من قبل</h4>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <h4>Reviewed By :</h4>
            <h4>:مراجعة من قبل</h4>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <h4>Approved By :</h4>
            <h4>:الموافقة من قبل</h4>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <h4>Position :</h4>
            <h4>:المنصب</h4>
          </td>
          <td class="text-center">
            <!-- <h4>Approved Gross Amount:</h4> -->
            <!-- <h4>اجمالي المبلغ الموافق عليه</h4> -->
          </td>
          <td class="text-center">
            <!-- <span>SAR  {{invoice_gross_amount}}</span> -->
          </td>
        </tr>
      </table>
    </div>
    <div class="row">
    <img src="./ifooter.png" alt="header" width="100%">
  </div>
  </div>
</template>
<script>
import HRMService from "../../../../services/hrm-service";
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoiceYasref",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      selected_expat: "progress",
      option_expat: [
        {
          text: "Progress",
          value: "progress",
        },
        {
          text: "Retention",
          value: "retention",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      selected_approval: "yes",
      option_approval: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      invoice: {},
      items: {},
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      invoice_rentention_amount: 0,
      invoice_net_amount: 0,
      amount_words: "",
      covered_period_response: {}
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },

  methods: {
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 });
    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"][0];
          this.covered_period_response = response.data["covered_period"];

          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount)
          // let total_amount = Number(this.items.amount)
          // let vat_invoice = total_amount * 0.15;
          // let gross_total = total_amount + vat_invoice; 
          // let retention = total_amount * 0.10;
          // let total_net = gross_total-retention;
          // this.invoice_total_amount = this.comma(total_amount);
          // this.invoice_vat_amount =  this.comma(vat_invoice);
          // this.invoice_gross_amount = this.comma(gross_total);;
          // this.invoice_rentention_amount = this.comma(retention);
          // this.invoice_net_amount = this.comma(total_net)

        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.fs-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.center {
  width: 1100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border {
  border: solid 2px black !important;
}

.word {
  text-transform: capitalize;
}

.fa-bold {
  font-weight: bold;
}

.box {
  width: 20px;
  height: 25px;
  border: 1px solid black;
}

.line-height {
  line-height: 10px;
}

.height-100 {
  height: 100px;
}

span {
  font-size: 15px;
}

tr td {
  font-weight: bold;
}

tr td h3,
h4 {
  font-weight: bold;
}

.border-top {
  border-top: solid 1px black !important;
}

.border-bottom {
  border-bottom: 3px double black !important;
}
</style>